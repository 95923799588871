<template id="modal-template">
    <transition name="modal">
        <div class="modal-mask">
            <div class="modal-wrapper">
                <div class="modal-container-confirm">
                    <div class="modal-body">
                        <slot name="body">
                            <div class="modal-body">
                                <div class="modal-body__content">
                                    <div class="modal-body__content-title">
                                        <span>
                                            {{ title }}
                                        </span>
                                        <i class="fa fa-times" @click="$emit('close')" />
                                    </div>
                                    <div v-if="state.preload" class="modal-body__content-body meeting-edit">
                                        <Preloaders :count="10" />
                                    </div>
                                    <div v-else class="modal-body__content-body meeting-edit">
                                        <div class="modal-body__content-body-header">成果内容</div>
                                        <div class="meeting-edit__form">
                                            <!-- 提出日 -->
                                            <div class="form-ctrl">
                                                <label class="form-title">
                                                    提出日
                                                </label>
                                                <div class="form-content">
                                                    {{ achievement.updated_at.split(" ")[0].replace(/-/g, '/') }}
                                                </div>
                                            </div>
                                            <!-- 成果報告書(zip) -->
                                            <div class="form-ctrl">
                                              <label class="form-title">
                                                成果報告書(zip)<br />
                                                <span class="alert">
                                                    ※1ファイル30MBまで
                                                </span>
                                              </label>
                                              <div class="form-content">
                                                      <span v-if="achievement.report_files">
                                                          <a :href="achievement.report_files[achievement.report_files.length - 1].url" target="_blank" rel="noreferrer noopener">
                                                              {{ achievement.report_files[achievement.report_files.length - 1].name }}
                                                          </a>
                                                      </span>
                                                <span v-else class="empty">
                                                    未入力
                                                </span>
                                              </div>
                                            </div>
                                            <!-- 成果物(zip) -->
                                            <div class="form-ctrl">
                                                <label class="form-title">
                                                    成果物(zip)<br />
                                                    <span class="alert">
                                                        ※1ファイル30MBまで
                                                    </span>
                                                </label>
                                                <div class="form-content">
                                                    <span v-if="achievement.files">
                                                        <a :href="achievement.files[achievement.files.length - 1].url" target="_blank" rel="noreferrer noopener">
                                                            {{ achievement.files[achievement.files.length - 1].name }}
                                                        </a>
                                                    </span>
                                                    <span v-else class="empty">
                                                        未入力
                                                    </span>
                                                </div>
                                            </div>
                                            <!-- 成果内容 -->
                                            <div class="form-ctrl">
                                                <label class="form-title">
                                                    成果内容
                                                </label>
                                                <div class="form-content">
                                                    <span v-if="achievement.description">
                                                        {{ achievement.description}}
                                                    </span>
                                                    <span v-else class="empty">
                                                        未入力
                                                    </span>
                                                </div>
                                            </div>
                                            <!-- 備考 -->
                                            <div class="form-ctrl">
                                                <label class="form-title">
                                                    備考
                                                </label>
                                                <div class="form-content">
                                                    <span v-if="achievement.note">
                                                        {{ achievement.note }}
                                                    </span>
                                                    <span v-else class="empty">
                                                        未入力
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="form-ctrl"  v-if="isOrganization">
                                                <div class="form-content" v-if="!achievement.browsed_by_organization">
                                                    <p class="form-explanation">
                                                      今後の取り組みのために、今回の成果に関して公開をお願いしております。<br>
                                                      本成果の取り扱いに関しまして、下記いずれかをご選択ください。
                                                    </p>
                                                    <div class="form-group" v-for="(publish) in publishList">
                                                        <input type="radio" :name="'publish_' + publish.id" :id="'publish_' + publish.id" :value="publish.id" v-model="state.publish" :disabled="achievement.browsed_by_organization" />
                                                        <label :for="'publish_' + publish.id">
                                                          {{ publish.name }} {{ publish.id == 2 ? '※修正したファイルは2/16(金)中に地域ハブ団体へご共有ください' : ''}}
                                                        </label>
                                                    </div>
                                                </div>
                                                <template v-else>
                                                    <label class="form-title">
                                                      本成果の取り扱い
                                                    </label>
                                                    <div class="form-content">
                                                        <span>
                                                        {{ publishList.find((publish) => publish.id == achievement.publish ).name }}
                                                        </span>
                                                    </div>
                                                </template>
                                            </div>
                                            <div class="form-ctrl"  v-if="isOrganization">
                                              <div class="form-content" v-if="!achievement.browsed_by_organization">
                                                <p class="form-explanation">
                                                  受講生には、地域企業協働プログラムを修了した証としてオープンバッジを発行しております。<br>
                                                  オープンバッジとは、世界共通の技術標準規格に沿って発行されるデジタル証明・認証で、<br>
                                                  受講生のSNSを通じた外部発信や、メール署名・履歴書等への活用が想定されます。<br>
                                                  マッチングPFにご登録いただいたプロジェクト名をオープンバッジに記載することを許可いただけますでしょうか？<br>
                                                    <span >
                                                    ※「公開を許可する」を選択した場合、マッチングPFに登録したプロジェクト名がオープンバッジに記載されます。<br>
                                                    協働期間中にゴールが変更となった・マッチングPFに登録したプロジェクト名に秘密情報が含まれる等の場合は、<br>
                                                    「一部に修正を加えることで公開を許可する」を選択し、オープンバッジ掲載用のプロジェクト名を記載ください。
                                                    </span>
                                                </p>
                                                <div class="form-group" v-for="(publish) in publishList">
                                                  <input type="radio" :name="'publish_to_badge_' + publish.id" :id="'publish_to_badge_' + publish.id" :value="publish.id" v-model="state.publish_to_badge" :disabled="achievement.browsed_by_organization" />
                                                  <label :for="'publish_to_badge_' + publish.id">
                                                    {{ publish.name }}
                                                  </label>
                                                </div>
                                              </div>
                                              <template v-else>
                                                <label class="form-title">
                                                  オープンバッジに記載
                                                </label>
                                                <div class="form-content">
                                                          <span>
                                                          {{ publishList.find((publish) => publish.id == achievement.publish_to_badge ).name }}
                                                          </span>
                                                </div>
                                              </template>
                                            </div>
                                            <div class="form-ctrl" v-if="isOrganization && state.publish_to_badge === 2">
                                              <label class="form-title">
                                                オープンバッジに記載するプロジェクト名
                                              </label>
                                              <div class="form-content" v-if="!achievement.browsed_by_organization">
                                                <input type="text" name="project_name_after_change" v-model="state.project_name_after_change" maxlength="255" required="true" placeholder="オープンバッジ掲載用のプロジェクト名を記載ください">
                                                <ErrorMessage
                                                    v-if="state.errors?.project_name_after_change"
                                                    :message="state.errors.project_name_after_change[0]"
                                                />
                                              </div>
                                              <div class="form-content" v-else>
                                                {{ achievement.project_name_after_change }}
                                              </div>
                                            </div>
                                            <div
                                                v-if="isOrganization && !achievement.browsed_by_organization"
                                                class="alert"
                                            >
                                                <p>
                                                   2/16(金)23:59までにご確認いただけなかった場合、「公開を許可する」ものと判断させていただきます。
                                                  <br/>
                                                    成果物を確認の上、問題がなければ「成果を受領する」を押して成果受領を完了させてください。
                                                    <br/>
                                                    ※受領後の受講生による成果物更新はできなくなります。
                                                </p>
                                            </div>
                                            <div
                                               v-if="!isOrganization && achievement.browsed_by_organization"
                                               class="alert"
                                            >
                                              <p>
                                                ※承認済みのため変更できません
                                              </p>
                                            </div>

                                        </div>
                                    </div>
                                    <div
                                        class="modal-body__content-button"
                                    >
                                        <button
                                            @click="$emit('close')"
                                            type="button"
                                            class="btn-light-gray btn-md shadow"
                                        >
                                            閉じる
                                        </button>
                                        <button
                                            v-if="isOrganization"
                                            type="button"
                                            class="btn-blue btn-md"
                                            :class="{
                                                disabled: achievement.browsed_by_organization,
                                                shadow : !achievement.browsed_by_organization,
                                            }"
                                            :disabled="achievement.browsed_by_organization || state.isSubmitting"
                                            @click="confirmAchievement"
                                        >
                                          <i v-if="state.isSubmitting" class="fas fa-circle-notch fa-spin"></i>
                                          {{ achievement.browsed_by_organization ? "成果を受領済み" : "成果を受領する" }}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script lang="ts" src="./AchievementConfirm.ts" />
<style lang="scss" src="./AchievementConfirm.scss" scoped></style>

